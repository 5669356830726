<template>
    <div class="content" style="position: relative">
        <div class="org-branch">
            <div class="org-branch-area">
                <div class="org-branch-node">
                    <div class="branch-node-name branch-node-leader">{{temple == 8 ? '院长' : '方丈'}}</div>
                </div>
                <org-branch :list="temple == 8 ? (list[0] && list[0].children ? list[0].children : []) : list" @select="handleSelect"></org-branch>
            </div>
        </div>
        <div class="dept-book-list" v-if="dept">
            <div class="org-dept-top">
                <open-data class="dept-top-name" type="departmentName" :openid="dept.id" />
                <a-icon class="org-dept-close" type="close" @click="dept = null"/>
            </div>
            <hr-book class="flex-grow" :dept="dept.id"></hr-book>
        </div>
    </div>
</template>

<script>
    import OrgBranch from "../../layouts/OrgBranch";
    import HrBook from "../../layouts/HrBook";
    import {getTemple} from "../../common/js/storage";
    // import WWOpenData from "WWOpenData";
    export default {
        name: "HROrganization",
        components: {
            OrgBranch,
            HrBook
        },
        data() {
            return {
                list: [],
                num: 0,
                dept: null,
                temple: getTemple()
            }
        },
        created() {
            this.getOrg();
        },
        methods: {
            handleSelect(dept) {
                this.dept = dept
            },
            getOrg() {
                if(this.$store.getters.deptList) {
                    this.dealList(this.$store.getters.deptList);
                } else {
                    let url = '/admin/common/get-dept-list';
                    this.$axios(url).then(res => {
                        if(res.error == 0) {
                            this.dealList(res.data)
                            this.$store.commit("setDeptList", res.data);
                        } else {
                            this.$message.warning(res.msg);
                        }
                    })
                }
            },
            dealList(list) {
                // let num = this.getDeptNum(list);
                // this.num = num;
                this.list = list;
            },
            getDeptNum(list) {
                let num = 0;
                list.forEach(item => {
                    num += 1;
                    if(item.children && item.children.length > 0) {
                        num += this.getDeptNum(item.children);
                    }
                });
                return num;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../common/less/org";
    .org-branch {
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        font-weight: 600;
        color: #1f63ca;
        line-height: 1;
    }
    .org-branch-area {
        padding: 0 20px;
        white-space: nowrap;
        .org-branch-children,
        .org-branch-node {
            display: inline-flex;
            vertical-align: middle;
        }
    }
    .dept-book-list {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 16px 24px;
        background-color: @component-background;
    }
    .org-dept-top {
        flex-shrink: 0;
        position: relative;
        padding-bottom: 16px;
        font-size: 20px;
        text-align: center;
        line-height: 1;
    }
    .org-dept-close {
        position: absolute;
        top: 0;
        right: 0;
        width: 32px;
        height: 32px;
        cursor: pointer;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
    }
</style>
