<template>
<div class="flex-box vertical">
  <div class="data-title">
    <div class="title-left flex-box">
      <span>共{{list.length}}位成员</span>
    </div>
    <a-form-model class="query-form" layout="inline">
      <a-form-model-item label="信息完善">
        <a-select
            style="width: 180px"
            placeholder="信息完善状态"
            @change="handleSearch"
            :options="enterStatusList"
            v-model="form.enterStatus">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="法名/姓名录入">
        <a-select
          style="width: 180px"
          placeholder="法名/姓名录入状态"
          @change="handleSearch"
          :options="nameStatusList"
          v-model="form.nameStatus">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="">
        <a-input-search allow-clear v-model="form.name" placeholder="输入姓名搜索" style="width: 180px" @search="handleSearch"/>
      </a-form-model-item>
    </a-form-model>
    <a-space class="title-btn">
      <a-button @click="handleSearch">查询</a-button>
      <a-dropdown :trigger="['click']" v-if="dept == 1">
        <a-button type="primary" :loading="exporting">{{exporting ? '正在导出' : '数据导出'}}</a-button>
        <a-menu slot="overlay">
          <a-menu-item :key="0" @click="exportData(1)">导出僧众</a-menu-item>
          <a-menu-item :key="1" @click="exportData(2)">导出职工</a-menu-item>
        </a-menu>
      </a-dropdown>
    </a-space>
  </div>
  <div class="data-area">
    <a-table
        ref="dataTable"
        :columns="columns"
        row-key="userid"
        :data-source="list"
        :loading="loading"
        :pagination="pagination"
        :scroll="{y: h}"
        @change="handleTableChange">
      <template v-slot:department="text">
        <span v-for="(d, i) in text" :key="d">
          {{i > 0 ? ";" : ""}}
          <open-data type="departmentName" :openid="d"/>
        </span>
      </template>
      <template v-slot:user="text, record">
        <span v-if="record.name">{{record.name}}</span>
        <open-data type="userName" :openid="text" v-else/>
      </template>
      <template v-slot:action="text, record">
        <a-space class="row-btn">
          <a class="txt-btn" @click.stop="showDetail(record)">查看</a>
          <a class="txt-btn" @click.stop="editBook(record)">编辑</a>
        </a-space>
      </template>
    </a-table>
  </div>
</div>
</template>

<script>

export default {
  name: "HrBook",
  props: {
    dept: {
      type: Number,
      default: 1
    },
  },
  data() {
    return {
      list: [],
      source: [],
      columns: [
        {title: '法名/姓名', dataIndex: 'userid', scopedSlots: { customRender: 'user'}},
        {title: '部门', dataIndex: 'department', scopedSlots: { customRender: 'department'}},
        {title: '信息完善', dataIndex: 'mobile', customRender: text => text ? '已完善' : '未完善'},
        {title: '法名/姓名录入', dataIndex: 'name', customRender: text => text ? '已录入' : '未录入'},
        {title: '操作', key: 'action', fixed: 'right', scopedSlots: { customRender: 'action'}}
      ],
      form: {},
      loading: false,
      enterStatusList: [
        {key: 0, title: '全部'},
        {key: 1, title: '已完善'},
        {key: 2, title: '未完善'},
      ],
      nameStatusList: [
        {key: 0, title: '全部'},
        {key: 1, title: '已录入'},
        {key: 2, title: '未录入'},
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showSizeChanger: true
      },
      h: 500,
      exporting: false
    }
  },
  created() {
    this.getList();
  },
  mounted() {
    this.setHeight();
    window.addEventListener("resize", this.setHeight, false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setHeight, false);
  },
  methods: {
    exportData(type) {
        if(this.exporting) return;
        this.exporting = true;
        let url = `/admin/evaluate-user/export?type=${type}`;
        this.$axios(url, {noTempleFilter: true, responseType: 'blob'}).then(res => {
            this.exporting = false;
            const content = res;
            const blob = new Blob([content])
            const fileName = `${type == 1 ? '僧众数据' : '职工数据'}.xlsx`;
            if ('download' in document.createElement('a')) { // 非IE下载
                const elink = document.createElement('a')
                elink.download = fileName;
                elink.style.display = 'none'
                elink.href = URL.createObjectURL(blob)
                document.body.appendChild(elink)
                elink.click()
                URL.revokeObjectURL(elink.href) // 释放URL 对象
                document.body.removeChild(elink)
            } else { // IE10+下载
                navigator.msSaveBlob(blob, fileName)
            }
        }).catch(() => {
            this.exporting = false;
        });
    },
    showDetail(record) {
      this.$router.push({name: 'HRBookDetail', params: {id: record.userid}})
    },
    editBook(record) {
      this.$router.push({name: 'HRBookForm', params: {type: 'staff'}, query: {b: record.userid}})
    },
    handleTableChange(pagination) {
      let pager = { ...this.pagination };
      pager.current = pagination.current;
      pager.pageSize = pagination.pageSize;
      this.pagination = pager;
      // this.getList();
    },
    getFilterList(list) {
      list = list || this.source;
      const {enterStatus, nameStatus} = this.form;
      if(enterStatus > 0) {
        if(enterStatus == 1) {
          list = list.filter(item => !!item.mobile);
        } else {
          list = list.filter(item => !item.mobile);
        }
      }
      if(nameStatus > 0) {
        if(nameStatus == 1) {
          list = list.filter(item => !!item.name);
        } else {
          list = list.filter(item => !item.name);
        }
      }
      this.list = list;
    },
    handleSearch() {
      let form = this.form;
      if(form.name) {
        if(this.loading) return;
        this.loading = true;
        let url = `/common/search-contact?keyword=${form.name}&queryType=1`;
        return this.$axios(url).then(res => {
          this.loading = false;
          if(res.error == 0) {
            let userList = res.data.user ? res.data.user.userid : null;
            const list = userList.map(u => this.source.find(b => b.userid == u));
            this.getFilterList(list)
          } else {
            this.$message.warning(res.msg);
          }
        }).catch(() => {
          this.loading = false;
        })
      } else {
        this.getFilterList();
      }
    },
    getList() {
      if(this.loading) return;
      this.loading = true;
      let url = `/admin/common/get-dept-user?dept=${this.dept}`;
      let form = this.form;
      if(form.type > 0) {
        url += `&filter[type]=${form.type}`
      }
      this.$axios(url).then(res => {
        this.loading = false;
        let list = res.data;
        this.list = list;
        this.source = list;
      }).catch(() => {
        this.loading = false;
      });
    },
    setHeight() {
      let dataTale = this.$refs.dataTable;
      if(dataTale) {
        let area = this.$el.querySelector(".data-area");
        this.h = area.clientHeight - 54 - 64;
      }
    },
  }
}
</script>

<style scoped lang="less">
</style>
