<template>
    <div class="org-branch-children" v-if="list && list.length > 0">
        <div class="org-branch-node"
             :class="{'org-branch-first': i === 0, 'org-branch-last': i === list.length - 1}"
             v-for="(c, i) in list"
             :key="c.id">
            <div class="org-branch-line"></div>
            <div class="branch-node-name" @click="handleClick(c)">
                <open-data class="node-dept-name" type="departmentName" :openid="c.id" />
            </div>
            <org-branch :list="c.children" v-if="c.children && c.children.length > 0" @select="handleClick"></org-branch>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrgBranch",
        props: {
            list: Array
        },
        methods: {
            handleClick(c) {
                this.$emit("select", c)
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../common/less/org";
</style>
